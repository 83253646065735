@import '../../../styles/index.scss';

.yatraPricingMain {
  padding: 50px 80px;
  color: var(--dark-bg-color);

  .heading {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .sectionsList{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 120px);
    gap: 20px;

    section{
      padding: 20px 25px;
      border-radius: 5px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
      }
  
      label {
        font-size: 17px;
        color: var(--grey-color);
      }
    }
  }
}

@include mini-screen {
  .yatraPricingMain {
    padding: 25px 20px;

    .sectionsList{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}