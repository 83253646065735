@import '../../../styles/index.scss';

.homeMain {

  .sliderContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: fit-content;

    .swiperSlide {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      max-height: 650px;
      background-color: var(--dark-bg-color);
    }

    .sliderImage {
      max-width: 100%;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .sectionHeading {
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.6px;
    color: var(--dark-bg-color);
    font-weight: 500;
    line-height: 50px;
  }

  .yatraText {
    font-size: 35px;
    text-align: center;
    letter-spacing: 0.6px;
    color: var(--dark-bg-color);
    font-weight: 500;
    line-height: 60px;
    margin: 0;
  }

  .contentControlsContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: center;
  }

  .informationContentContainer {
    padding: 80px;

    .informationContent {
      width: 1000px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin: 0 auto;
    }

    .contentLabel {
      color: var(--grey-color);
      font-size: 24px;
      text-align: center;
      line-height: 35px;
    }

    .signupNowControl {
      width: 200px;
      height: 50px;
      color: var(--white-color);
      background-color: var(--primary-color);
      border: none;
      outline: none;
      border-radius: 5px;
      margin: 20px 0;
      font-size: 20px;
      letter-spacing: 0.3px;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      }
    }

  }

  .horizontalDivider {
    border-top: 1px solid var(--border-color);
    margin: 0 80px;
  }

  .tentativeScheduleCotainer {
    padding: 40px 80px;

    .header {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .scheduleListContainer {
      width: 1080px;
      margin: 40px auto;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: calc(50% - 25px);
      column-gap: 50px;
      justify-content: space-evenly;
      align-items: center;
    }

    .scheduleList {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }

    .scheduleItem {
      display: grid;
      grid-template-columns: 120px auto;
      column-gap: 25px;
      align-items: center;

      &.left {
        direction: rtl;

        .infoLabel {
          text-align: right;
        }
      }
    }

    .dayLabelContainer {
      width: 120px;
      height: 50px;
      border-radius: 50px;
      display: grid;
      place-content: center;
      background-color: var(--primary-color);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .dayLabel {
        color: var(--white-color);
        font-size: 20px;
      }
    }

    .infoLabel {
      color: var(--grey-color);
      font-size: 18px;
      letter-spacing: 0.4px;
    }

  }

}

@include large-screen {
  .homeMain {

    .tentativeScheduleCotainer {
      .scheduleListContainer {
        width: 900px;
      }
    }

  }
}

@include medium-screen {
  .homeMain {

    .sliderContainer {
      max-height: 580px;

      .sliderImage {
        width: 100%;
      }
    }

    .informationContentContainer {
      padding: 80px 40px;

      .informationContent {
        width: 600px;
      }
    }

    .horizontalDivider {
      margin: 0 40px;
    }

    .tentativeScheduleCotainer {
      .scheduleListContainer {
        width: 100%;
        grid-template-columns: 100%;
        grid-auto-flow: row;
        row-gap: 25px;
      }

      .scheduleItem {
        &.left {
          direction: ltr;

          .infoLabel {
            text-align: left;
          }
        }
      }

    }

  }
}

@include mini-screen {
  .homeMain {

    .informationContentContainer {

      .informationContent {
        width: 460px;
      }
    }

    .tentativeScheduleCotainer {
      padding: 40px 25px;

      .scheduleItem {
        grid-template-columns: 75px auto;
        column-gap: 15px;

        .dayLabelContainer {
          width: 75px;
          height: 35px;
          border-radius: 35px;

          .dayLabel {
            color: var(--white-color);
            font-size: 20px;
          }
        }
      }
    }

  }
}

@include small-screen {
  .homeMain {

    .sliderContainer {
      max-height: 280px;
    }

    .sectionHeading {
      font-size: 25px;
      line-height: 40px;
    }

    .informationContentContainer {
      padding: 40px 20px;

      .informationContent {
        width: 340px;
        row-gap: 15px;
      }

      .contentLabel {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .horizontalDivider {
      margin: 0 20px;
    }

  }
}