@import '../../../styles/index.scss';

.footerMain {
  background-color: var(--dark-bg-color);
  width: 100%;
  padding: 25px 80px;
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: space-between;

  .navigationLinkControls {
    display: flex;
    align-items: center;
    column-gap: 30px;
  }

  .navigationLink {
    color: var(--white-color);
    font-size: 15px;
    letter-spacing: 0.3px;
    text-decoration: none;

    &:hover {
      color: var(--primary-color);
    }
  }

  .socialMediaLinks {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .socialMediaLink {
    .icon {
      font-size: 25px;
      color: var(--white-color) !important;

      &:hover {
        color: var(--primary-color) !important;
      }
    }
  }

}

@include large-screen {
  .footerMain {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
}

@include medium-screen {
  .footerMain {

    .navigationLinkControls {
      flex-direction: column;
      row-gap: 20px;
      width: 100%;
    }

  }
}

@include small-screen {
  .footerMain {
    padding: 15px 20px;

    .navigationLink {
      font-size: 17px;
    }
  }
}