@import '../../../styles/index.scss';

.termsAndConditionsMain {
  padding: 50px 80px;
  color: var(--dark-bg-color);

  .heading {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .parentSection {

    .sectionHeading {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .sectionSubHeading {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    p {
      font-size: 17px;
      color: var(--grey-color);

      strong {
        margin-right: 6px;
      }
    }
  }

}

@include medium-screen {
  .termsAndConditionsMain {
    padding: 25px 40px;
  }
}

@include small-screen {
  .termsAndConditionsMain {
    padding: 25px 20px;
  }
}