@import '../../../styles/index.scss';

#faqsMain {
  padding: 50px 80px;
  color: var(--dark-bg-color);
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .heading {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  .question {

    div {
      font-size: 17px;
      font-weight: 600;
    }

  }

  .image {
    width: 400px;
    height: auto;
    border-radius: 5px;
    margin: 15px 0;
  }
}

@include medium-screen {
  #faqsMain {
    padding: 25px 40px;
  }
}

@include small-screen {
  #faqsMain {
    padding: 25px 20px;

    .image{
      width: 100%;
    }
  }
}