html,
body,
#rps-app-container,
#root {
  height: 100%;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
}


body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */

  /* background-image:url(http://www.romapadaswami.com/wp-content/uploads/2019/05/Slider_Knowledge.jpg); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rps-flex {
  display: flex;
  width: 100%;
}

.flex-one {
  flex: 1;
  margin-top: '10px';
  text-align: center;
}

.section-header {
  margin-bottom: 1em;
}

.rps-flex-column {
  display: flex;
  flex-direction: column;
}


.rps-app-header-appbar {
  /* background-color: #c65c00; */
  background-color: black !important;
  width: 100%;
  color: #ffff;
}

.nav-bar .nav-item {
  color: #c65c00;
}

#user-menu-mobile,
#user-menu-desktop {
  justify-content: flex-end;
}

#rps-app-logo img {
  margin-top: 5px;
}

#rps-app-content {}



/* Home */

.home-carousel {
  height: 300px;
}

.home-carousel-item,
.carousel-inner {
  height: 100%;
}


.MuiContainer-root:not(#rps-app-header .MuiContainer-root) {
  height: 100%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}


#user-details-form {
  display: flex;
  flex-direction: column;
  overflow: auto;

}


/* Login */


.rps-bg-img {
  background-image: url(http://www.romapadaswami.com/wp-content/uploads/2019/05/Slider_Knowledge.jpg);
}

/* Registration For */

.registration-form {
  display: flex;
  height: 100%;
  flex-direction: column;

}

.registration-form-stepper {
  padding: 0.3em 0em;
}

.registration-form-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 0.5em;
}

.registration-form-content-sec {
  flex: 1;
  overflow: auto;
}

.registration-form-content-actions {
  /* height: 100px; */
  padding: 0.2em;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registration-form-content-actions button,
.empty-container button,
.btn-primary,
.form-group button,
.summary-container .MuiButton-root:not(header .MuiButton-root) {
  min-width: 50px;
  min-height: 40px;
  background-color: #c46800 !important;
  border-color: #c46800;
}

.registration-form-content-actions button,
.empty-container button,
.summary-container .MuiButton-root:not(header .MuiButton-root) {
  margin: 0px 0.25em;
}

/* Media query for small screens */
@media screen and (min-width: 600px) {
  .registration-form-content-actions {
    flex-direction: row;
    padding: 1em;
    /* Display buttons in a row on larger screens */
    justify-content: space-between;
    /* Spread buttons horizontally */
    /* align-items: center; */
    flex-wrap: wrap;
  }

  .registration-form-content-actions button {
    margin: 0;
    /* Remove margin between buttons */
    min-width: 150px;
    min-height: 50px;
  }
  .registration-form-stepper {
    padding: 2em 0em;
  }
}

.Mui-active,
.MuiSvgIcon-root,
#menu-appbar a {
  color: #c46800 !important;
}

.empty-container button,
.MuiButton-endIcon .MuiSvgIcon-root {
  color: #fff !important;
}


.form-group .btn {
  background-color: #c46800 !important;
  border-color: #c46800;
}

#menu-appbar a {
  font-weight: bold;
}

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  flex: 1;
  flex-direction: column;
  border-radius: 4px;

}

.empty-container p {
  font-weight: bold;
}

.page-section {
  flex: 1;
  overflow: auto;
  /* padding: 1em 0px; */
}

.room-form {
  border: 2px solid #ccc;
  padding: 2em;
  border-radius: 4px;
}


#registration-form-table {
  flex: 1;
  overflow: auto;
}

#registration-form-table th,
#registration-form-table td {
  padding: 1em;

}

.summary-container {
  display: flex;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.xyz-white-icon {
  color: white !important;
}

.slim-button button {
  background-color: #c46800 !important;
  border-color: #c46800;
  min-width: 100px;
  min-height: 30px;
}