label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* style.css */
.member-form {
  border: 1px solid #ddd; /* Border around each member form */
  padding: 15px; /* Padding inside the form */
  margin-bottom: 20px; /* Space below each form */
  background-color: #f9f9f9; /* Light background for the form */
  border-radius: 5px; /* Slightly rounded corners for the box */
}

.member-separator {
  height: 20px; /* Larger separator height */
  background-color: #eaeaea; /* Background color for the separator */
  margin: 20px 0; /* Space above and below the separator */
}

.form-group {
  margin-bottom: 10px; /* Adjust form group spacing */
}


/* styles.css */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  padding: 0px;
}
@media screen and (min-width: 600px) {
  body {
    /* padding: 20px; */
  } 
}

.form-container {
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="number"],
.form-group select,
.react-datepicker-wrapper {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0;
  /* Square edges */
  box-sizing: border-box;
}

.form-group-button {
  display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-group-button:hover {
  background-color: #003d82;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

/* style.css */
.nav-bar {
  display: flex;
  justify-content: space-around;
  background-color: #eee;
  padding: 10px;
  margin-bottom: 20px;
}

.nav-item {
  cursor: pointer;
  padding: 5px 10px;
}

.nav-item.active {
  background-color: #ddd;
  border-radius: 5px;
}

.summary-section {
  margin-bottom: 20px;
}

.summary-item {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}