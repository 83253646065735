.sidebarMain{
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  .sidebarContainer{
    width: 300px;
    height: 100%;
    background-color: var(--white-color);
    color: var(--dark-bg-color);
    overflow-y: auto; /* Add vertical scrolling */
  }

  .sidebarHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-color);

    label{
      font-size: 20px;
    }
  }

  .sidebarLinksContainer{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
  }

  .sidebarLink {
    display: inline-block;
    padding: 10px 20px 10px 30px;
    color: var(--grey-color);
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease;
    line-height: 1; /* Center text vertically */
  }
  
  .sidebarLink:hover {
    color: var(--primary-color);
    background-color: var(--grey-color); /* Change to the desired background color */
    border-color: var(--primary-color); /* Change to the desired border color on hover */
  }
  

  .sectionDivider{
    border-top: 1px solid var(--border-color);
    margin: 10px 0;
  }
}