@import '../../../styles/index.scss';

.topNavigationBartMain {
  background-color: var(--dark-bg-color);
  width: 100%;
  padding: 15px 80px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  box-shadow: 0 -2px 10px #000;
  display: flex;
  align-items: center;
  column-gap: 70% 30%;
  justify-content: space-between;

  .logoSection {
    display: flex;
    align-items: center;
    column-gap: 30px;

    .menuControl {
      color: var(--primary-color);
    }

    .logoSectionLabel {
      color: var(--white-color);
      font-size: 20px;
      letter-spacing: 0.5px;
      cursor: pointer;
    }
  }

  .loginSignupControls {
    display: flex;
    align-items: center;
    column-gap: 40px;

    .loginSignupLink {
      color: var(--white-color);
      font-size: 20px;
      letter-spacing: 0.3px;
      text-decoration: none;

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

@include medium-screen {
  .topNavigationBartMain {
    padding: 15px 40px;
  }
}

@include small-screen {
  .topNavigationBartMain {
    padding: 15px 20px;
    height: 60px;

    .logoSection {
      column-gap: 20px;

      .logoSectionLabel {
        font-size: 17px;
      }
    }

    .loginSignupControls {
      column-gap: 20px;

      .loginSignupLink {
        font-size: 17px;
      }
    }
  }
}